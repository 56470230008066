import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PageBanner from '../components/banner';

const PostItem = ({ post }) => {
  const icon = getImage(post.frontmatter.icon);
  return (
    <div style={{ display: 'flex', marginBottom: '0.5em' }}>
      <GatsbyImage
        image={icon}
        imgClassName="post-icon"
        alt={`${post.frontmatter.title}-icon`}
      />
      &nbsp;
      <div style={{ width: '100%' }}>
        <p style={{ fontSize: 'small' }}>{post.frontmatter.date} </p>
        <p>
          <Link className="post-item" to={`${post.fields.slug}`}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid rgba(var(--border-color), .16)'
              }}
            >
              <p>{post.frontmatter.title}</p>
              <p>&rarr;</p>
            </div>
          </Link>
        </p>
      </div>
    </div>
  );
};

function Posts({ data, location }) {
  const posts = data?.allMarkdownRemark.nodes;
  const [tags, setTags] = useState([]);
  console.log(data);

  useEffect(() => {
    if (posts?.length) {
      const allTags = new Set();
      posts.forEach(post => {
        post.frontmatter.tags.forEach(tag => allTags.add(tag));
      });
      setTags(allTags);
    }
  }, [posts]);

  return (
    <Layout location={location} title="blog" page="blog">
      <div className="posts-list">
        <h2>All Posts</h2>
        <section className="post-items">
          {posts?.map(post => (
            <PostItem key={post.title} post={post} />
          ))}
        </section>
      </div>
    </Layout>
  );
}
export default Posts;

export const Head = () => <Seo title="Blog" />;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMM YYYY")
          title
          description
          tags
          image_alt
          image_credit_text
          image {
            childImageSharp {
              gatsbyImageData(height: 200, width: 400)
            }
          }
          icon {
            childImageSharp {
              gatsbyImageData(height: 24, width: 24)
            }
          }
        }
      }
    }
  }
`;
